import photoWhiteBlack from './outTurn.jpg';
import photo from './aboutPage.jpg';
import Excellence from '../Excellence/Excellence';
import Doki from '../Doki/Doki';
import Projects from '../Projects/Projects';
import Contacts from '../Contacts/Contacts';

function FirstProduct() {
  return (
    <>
      <div className="f__product__topic">
        <h1>Вертикальная разметка для барьерных ограждений</h1>
      </div>
      <div className="wrapper__out">
        <div className="block__row__out">
          <div className="block__column" style={{ paddingTop: '10%' }}>
            <img src={photo} alt="promo" align="middle" width="60%" height="100%" />
          </div>
          <div className="block__column__out">
            <div className="block__item__out text__out">
              <p>
                Долговременная вертикальная дорожная разметка, для металлических
                барьерных ограждений создаваемая по ГОСТ 52289-2019 за счет
                применения Защитных пластиковых панелей. Панели выполнены в виде
                пластикового профиля, отлитого в заводских условиях по полимерной
                технологии.
              </p>
              <p className="p__out">
                Наша продукция выпускается в соответствии с ОДМ 218.5.007-2008 и СТО
                224610.01-2005 и включена в «Реестр новых и наилучших технологий
                материалов и технологических решений» Минтранса России,
                рекомендованных к применению в рамках Национального проекта
                «Безопасные качественные дороги».
              </p>
            </div>
          </div>
          <div className="block__column">
            <img
              src={photoWhiteBlack}
              alt="promo"
              align="middle"
              width="60%"
              height="100%"
            />
          </div>
          <Excellence />
          <Projects />
          <Doki />
          <Contacts />
        </div>
      </div>
    </>
  );
}

export default FirstProduct;
