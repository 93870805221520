import { useNavigate } from 'react-router-dom';

function NavBar() {
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-light bg-light justify-content-between">
      <div className="navbar">
        <h1 className="title__nav"> ЗАО "БЕРМ" </h1>
      </div>
      <div className="nav_components">
        <div className="nav_item">
          <div
            className="nav_comp"
            onClick={() => {
              navigate('/');
            }}
          >
            О компании
          </div>

          <div className="dropdown">
            <span>Продукция</span>
            <div className="dropdown-content">
              <div className="dropdown_flex">
                <div
                  className="drop_text"
                  onClick={() => {
                    navigate('/first_product');
                  }}
                >
                  Вертикальная разметка для барьерных ограждений
                </div>

                <hr className="hr-shelf" />

                <div
                  className="drop_text"
                  onClick={() => {
                    navigate('/second_product');
                  }}
                >
                  Дорожный световозвращатель, полимерный усиленный (катафот КД-5)
                </div>
              </div>
            </div>
          </div>

          <div
            className="nav_comp"
            onClick={() => {
              navigate('/contacts');
            }}
          >
            Контакты
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
