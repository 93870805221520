import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contacts from './components/Contacts/Contacts';
import Main from './components/Main/Main';
import NavBar from './components/Navbar/Navbar';
import FirstProduct from './components/FirstProduct/FirstProduct';
import SecondProduct from './components/SecondProduct/SecondProduct';

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="" element={<Main />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="first_product" element={<FirstProduct />} />
          <Route path="second_product" element={<SecondProduct />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
