import photo1 from './Фотографии реализованных проектов/1.jpg';
import photo2 from './Фотографии реализованных проектов/2.JPG';
import photo3 from './Фотографии реализованных проектов/3.JPG';
import photo4 from './Фотографии реализованных проектов/4.JPG';
import photo5 from './Фотографии реализованных проектов/5.JPG';
import photo6 from './Фотографии реализованных проектов/6.JPG';

function Projects() {
  return (
    <div className="projects">
      <div className="block__row__topic__projects">
        <div className="block__column__topic__projects">
          <div className="block__item__topic__projects">
            <h1>ПРОЕКТЫ</h1>
            <hr className="hr__projects" />
          </div>
        </div>
      </div>
      <div className="block__row__projects">
        <div className="block__column__projects">
          <img
            src={photo1}
            alt="photo1"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround">
            <p className='p__projects'>
              г. Москва - <br />
              г. Санкт-Петербург
            </p>
          </div>
        </div>
        <div className="block__column__projects">
          <img
            src={photo2}
            alt="photo2"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround">
            <p className='p__projects'>г. Москва - г. Минск</p>
          </div>
        </div>
        <div className="block__column__projects">
          <img
            src={photo3}
            alt="photo3"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround">
            <p className='p__projects'>Ленинградская область</p>
          </div>
        </div>
        <div className="block__column__projects">
          <img
            src={photo4}
            alt="photo4"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround">
            <p className='p__projects'>МКАД г. Москва</p>
          </div>
        </div>
        <div className="block__column__projects">
          <img
            src={photo5}
            alt="photo5"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround">
            <p className='p__projects'>Кавказ г. Беслан</p>
          </div>
        </div>
        <div className="block__column__projects">
          <img
            src={photo6}
            alt="photo6"
            style={{
              width: '10%',
              height: '230px',
              objectFit: 'cover',
              position: 'absolute',
              paddingTop: '20px',
            }}
          />
          <div className="backGround" style={{ wordSpacing: '5px' }}>
            <p className='p__projects'>Московская область</p>
          </div>
        </div>
      </div>
      <div className="backgraundBig"></div>
    </div>
  );
}

export default Projects;
