import Contacts from '../Contacts/Contacts';
import './Main.css';
import photo from './Main.png';
import sec_prod from './second_product.jpg';
import first_prod from './aboutPage.jpg';
import { useNavigate } from 'react-router-dom';

function Main() {
  const navigate = useNavigate();
  return (
    <main className="main">
      <div className="content">
        <div>
          <h1 className="h1__main" style={{ paddingTop: '100px' }}>
            Инновационные решения для дорожного строительства
          </h1>
        </div>
      </div>
      <div className="wrapper__about">
        <div className="block__row__about">
          <div className="block__column__about">
            <div className="block__item__about padding__br">
              <h1>О компании</h1>
            </div>
            <br />
            <br />
            <br />
            <div className="block__item__about">
              <p>
                ЗАО "Берм" – более 17 лет занимается разработкой и производством
                инновационных технологий и материалов для дорожного строительства
                <br />
                <br />
                <br />
                Наша основная цель – повысить уровень безопасности дорожного движения
                на дорогах России за счет применения отечественных перспективных
                разработок.
              </p>
            </div>
          </div>
          <div className="block__column">
            <div className="block__item" style={{ padding: '50px' }}>
              <img
                src={photo}
                alt="promo"
                align="middle"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div className="our__projects">
          <div
            className="our__projects__items"
            onClick={() => {
              navigate('/first_product');
            }}
          >
            <div className="our__projects__text">
              <p>Вертикальная разметка для барьерных ограждений </p>
            </div>
            <div className="our__projects__photo">
              <img
                src={first_prod}
                alt="promo"
                align="middle"
                width="70%"
                height="70%"
              />
            </div>
          </div>
          <div
            className="our__projects__items"
            onClick={() => {
              navigate('/second_product');
            }}
          >
            <div className="our__projects__text">
              <p>Дорожный световозвращатель, полимерный усиленный (катафот КД-5)</p>
            </div>
            <div className="our__projects__photo">
              <img
                src={sec_prod}
                alt="promo"
                align="middle"
                width="70%"
                height="70%"
              />
            </div>
          </div>
        </div>

        <Contacts />
      </div>
    </main>
  );
}

export default Main;
