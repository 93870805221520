function Contacts() {
  return (
    <>
      <div className="wrapper__contacts" style={{ paddingTop: '100px' }}>
        <div className="block__row__contacts__topic">
          <div className="block__column" style={{ paddingBottom: '40px' }}>
            <div className="block__item__contacts__topic">
              <h1>КОНТАКТЫ</h1>
              <hr className="hr__contacts" />
            </div>
          </div>
        </div>
        <div className="block__row__contacts">
          <div className="block__column__contacts">
            <div className="block__item__contacts">
              <div style={{ textAlign: 'center' }}>
                <p className="p__contacts" style={{ fontWeight: '700' }}>
                  ЗАО "БЕРМ"
                </p>
                <p className="p__contacts">Электролитный проезд, д.5Б (17А),</p>
                <p className="p__contacts">Москва 115230, Россия</p>
                <br />
                <p className="p__contacts">Email: mos-berm@yandex.ru</p>
                <p className="p__contacts">Телефон: +7 (977) 977-92-88</p>
                <p className="p__contacts">+7 (985) 419-11-93</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contacts;
