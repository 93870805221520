import Contacts from '../Contacts/Contacts';
import photo from './second_product.jpg';
import photo2 from './photo2.jpg';
import table from './table.png';
import excellence from './photo.jpg';
// import Doki from '../Doki/Doki';
function SecondProduct() {
  return (
    <>
      <div className="title__product">
        <h1>Дорожный световозвращатель, полимерный усиленный (катафот КД-5)</h1>
      </div>
      <div className="wrapper__out">
        <div className="block__row__out">
          <div className="block__column" style={{ paddingTop: '10%' }}>
            <img src={photo} alt="promo" align="middle" width="60%" height="100%" />
          </div>
          <div className="block__column__out">
            <div className="block__item__out text__out">
              <p style={{ fontSize: '25px' }}>
                Световозвращатель, изготовленный по инновационной технологии из
                полимерно-композиционного материала, снабженного пленочным
                светоотражающим элементом. Обладает{' '}
                <b>повышенными прочностными характеристиками</b> в сравнении с
                конструкцией рекомендованной требованиями ГОСТ 32866 и ГОСТ Р 50971.
              </p>
            </div>
          </div>
          <div className="block__column">
            {' '}
            <img
              src={table}
              alt="excellence"
              style={{
                width: '70%',
                height: '100%',
                padding: '25px 0px',
              }}
            />
          </div>
          <div className="block__column">
            <div
              className="block__item__out"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}
            >
              <p style={{ fontSize: '25px', width: '35%' }}>
                Световозвращатель, изготовленный по инновационной технологии из
                полимерно-композиционного материала, снабженного пленочным
                светоотражающим элементом. Обладает{' '}
                <b>повышенными прочностными характеристиками</b> в сравнении с
                конструкцией рекомендованной требованиями ГОСТ 32866 и ГОСТ Р 50971.
              </p>
              <img src={photo2} alt="promo" align="middle" width="25%" />
            </div>
          </div>
          <div className="excellence__second__product">
            <img
              src={excellence}
              alt="excellence"
              style={{
                objectFit: 'cover',
                zIndex: -1,
                position: 'absolute',
                height: '26%',
              }}
            />
            <div style={{ width: '70%' }}>
              <div className="block__row__topic__exc">
                <div className="block__column__topic__exc">
                  <div className="block__item__topic__exc">
                    <div className="topic">
                      <h1>ПРЕИМУЩЕСТВА</h1>
                      <hr className="hr__topic__exc" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll">
                <div
                  style={{
                    paddingTop: '50px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    minHeight: '530px',
                    flexWrap: 'wrap',
                    height: '700px',
                  }}
                >
                  <div
                    className="block__column__exc"
                    style={{ width: '35%', height: '35%' }}
                  >
                    <div className="block__item__exc">
                      <div className="p__excellence__sec__product">
                        Использованный полимерный материал не подвержен коррозии
                      </div>
                    </div>
                  </div>
                  <div
                    className="block__column__exc"
                    style={{ width: '35%', height: '35%' }}
                  >
                    <div className="block__item__exc">
                      <div className="p__excellence__sec__product">
                        Проведенные сравнительные испытания на разрушение под
                        нагрузкой показали, что корпус,{' '}
                        <b>
                          изготовленный по полимерной технологии, в 2-2,5 раза
                          прочнее корпуса из металла.
                        </b>
                      </div>
                    </div>
                  </div>
                  <div
                    className="block__column__exc"
                    style={{ width: '35%', height: '35%' }}
                  >
                    <div className="block__item__exc">
                      <div className="p__excellence__sec__product">
                        Полимерная технология позволяет окрашивать весь массив
                        корпуса в <b>заданный сигнальный цвет</b>. Это дает
                        возможность эксплуатировать световозвращатели весь срок
                        службы без необходимости повторного окрашивания.
                      </div>
                    </div>
                  </div>
                  <div
                    className="block__column__exc"
                    style={{ width: '35%', height: '35%' }}
                  >
                    <div className="block__item__exc">
                      <div className="p__excellence__sec__product">
                        Создание на рабочей поверхности катафота небольшого
                        углубления под размер световозвращающей пленки позволяет{' '}
                        <b>предотвратить затекание влаги</b> под края пленки,{' '}
                        <b>
                          защищает кромку пленки от механических воздействий щетки
                        </b>
                        , что защищает ее от отслаивания в процессе эксплуатации.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Doki /> */}
          <Contacts />
        </div>
      </div>
    </>
  );
}

export default SecondProduct;
