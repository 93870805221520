import photo from './Excellence.jpg';

function Excellence() {
  return (
    <div>
      <img
        src={photo}
        alt="excellence"
        style={{
          width: '100%',
          objectFit: 'cover',
          zIndex: -1,
          position: 'absolute',
        }}
      />
      <div className="wrapper__exc">
        <div className="block__row__topic__exc">
          <div className="block__column__topic__exc">
            <div className="block__item__topic__exc">
              <div className="topic">
                <h1>ПРЕИМУЩЕСТВА</h1>
                <hr className="hr__topic__exc" />
              </div>
            </div>
          </div>
        </div>
        <div className="scroll">
          <div className="block__row__exc">
            <div className="block__column__exc">
              <div className="block__item__exc">
                <div>
                  <h1 className="h1__exc">Безопасность</h1>
                  <hr className="hr__exc" />
                  <div>
                    <p className="p__ind">
                      Повышение уровня безопасности дорожного движения на
                      потенциально опасных участках автодорог;{' '}
                    </p>
                    <p className="p__ind">
                      Соответствие продукции всем требованиям действующих нормативных
                      документов (ГОСТ) по безопасности дорожного движения.{' '}
                    </p>
                    <p className="p__ind">
                      Повышение контрастности ограждения на общем фоне для всех
                      участников дорожного движения;{' '}
                    </p>
                    <p className="p__ind">
                      Стойкость к внешним ударном воздействию;{' '}
                    </p>
                    <p className="p__ind">
                      Цветовое исполнение – в виде чередующихся полос черного и
                      белого цвета; Экологически чистый материал.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="block__column__exc">
              <div className="block__item__exc">
                <div>
                  <h1 className="h1__exc">Экономичность</h1>
                  <hr
                    className="hr__exc"
                    style={{ marginLeft: '25%', marginRight: '25%' }}
                  />
                  <div>
                    <p className="p__ind">
                      Подтвержденный срок службы вертикальной разметки составляет
                      более 7 лет;{' '}
                    </p>
                    <p className="p__ind">
                      Отказ от ежегодного окрашивания ограждения при нанесении
                      вертикальной разметки (покраски);{' '}
                    </p>
                    <p className="p__ind">
                      Возможность применения механизированных методов очистки
                      вертикальной разметки и ограждений от климатических
                      загрязнений;{' '}
                    </p>
                    <p className="p__ind">
                      Дополнительная защита металлического барьерного ограждения от
                      негативного воздействия агрессивной техногенной среды;{' '}
                    </p>
                    <p className="p__ind">
                      Удобство и экономичность в монтаже и эксплуатации панелей;
                    </p>
                    <p className="p__ind">
                      Улучшение общего эстетического вида автодороги;
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="block__column__exc">
              <div className="block__item__exc">
                <div>
                  <h1 className="h1__exc">Документация</h1>
                  <hr
                    className="hr__exc"
                    style={{ marginLeft: '25%', marginRight: '25%' }}
                  />
                  <div>
                    <p className="p__ind">
                      Наличие документации подтверждающих безопасность применения
                      пластиковых панелей для участников дорожного движения;{' '}
                    </p>
                    <p className="p__ind">
                      Панели одобрены к применению в Главном управлении по
                      обеспечению безопасности дорожного движения МВД России и
                      Техническом комитете по стандартизации «БДД» ТК 278;{' '}
                    </p>
                    <p className="p__ind">
                      Утверждены Федеральные и Территориальные расценки на данный вид
                      продукции;{' '}
                    </p>
                    <p className="p__ind">
                      Продукция включена в единый «Классификатор строительных
                      ресурсов» и «Реестр новых и наилучших технологий я» Минтранса
                      России.{' '}
                    </p>
                    <p className="p__ind">
                      Сертификат соответствия подтверждает соответствие требованию
                      ГОСТ.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Excellence;
