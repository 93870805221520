import mosstroycheni from './Документация/Мосстройцены.pdf';
import mvd from './Документация/МВДРФ.pdf';
import mp from './Документация/МР.pdf';
import ott from './Документация/ОТТ.pdf';
import cc from './Документация/СС.pdf';
import tk278 from './Документация/ТК278.pdf';
import fr from './Документация/ФР.pdf';
import pdf from './pdf.png';
import photo from './doki.jpg';

function Doki() {
  return (
    <div className="doki">
      <img
        src={photo}
        alt="excellence"
        style={{
          width: '100%',
          height: '500px',
          objectFit: 'cover',
          zIndex: -1,
          position: 'absolute',
          paddingTop: '20px',
        }}
      />
      <div className="wrapper__doki__doki">
        <div className="block__row__topic__doki">
          <div className="block__column__topic__doki">
            <div className="block__item__topic__doki">
              <div className="topic">
                <h1>ДОКУМЕНТАЦИЯ</h1>
                <hr className="hr__doki" />
              </div>
            </div>
          </div>
        </div>
        <div className="block__row__doki">
          <div className="block__column__doki">
            <div className="block__item__doki">
              <a href={mosstroycheni} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Мосстройцены</p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={mvd} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Заключение ГУОБДД МВД РФ</p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={mp} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Методические рекомендации</p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={ott} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Общие тех. требования</p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={cc} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Сертификат соответствия</p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={tk278} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">
                  Заключение <br />
                  ТК 278
                </p>
              </div>
            </div>
          </div>
          <div className="block__column__doki">
            <div className="block__item__doki" style={{ width: '100%' }}>
              <a href={fr} rel="noreferrer" target="_blank">
                <img
                  src={pdf}
                  alt="pdf"
                  style={{
                    width: '6%',
                    height: '200px',
                    objectFit: 'contain',
                    position: 'absolute',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                  }}
                />
              </a>
              <div className="backGround__doki">
                <p className="p__dok">Федеральные расценки</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Doki;
